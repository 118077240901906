



































.script-succeed {
  padding-bottom: 60px;
  .script-succeed-icon {
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    i {
      font-size: 52px;
      color: #20d28c;
    }
    span {
      color: #343434;
      font-size: 24px;
      font-weight: bold;
    }
  }
  .getBack {
    display: flex;
    justify-content: center;
    padding-top: 30px;
    .getBackBtn {
      width: 100px;
      height: 40px;
      background: #ffffff;
      line-height: 0;
      border-radius: 2px;
    }
  }
}
